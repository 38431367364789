import {
	saveCartItem,
	clearCartItem,
	CalculatePriceIncVat,
	storeBuyerTourGuide,
	saveCompanyInfo,
	saveUserSetting,
	saveUserSettingHideFieldStatus,
} from "./../../../../services/storage-window";

export default {
	UPDATE_ARTICLES(state, payload) {
		state.copyArticles = JSON.parse(JSON.stringify(payload));
		state.extraCopy = JSON.parse(JSON.stringify(payload));
		state.articles = JSON.parse(JSON.stringify(payload));
	},
	UPDATE_COMPANY_SETTINGS(state, payload) {
		state.companySettings = payload;
	},
	UPDATE_USER_SETTINGS(state, payload) {
		saveUserSetting(payload);
	},
	UPDATE_USER_SETTINGS_HIDE_FIELD_STATUS(state, payload) {
		saveUserSettingHideFieldStatus(payload);
	},
	CLEAR_CART(state, _) {
		state.cartProduct = [];
		clearCartItem();
	},
	UPDATE_BILLING_INFO(state, payload) {
		state.billing = payload;
	},
	UPDATE_COUNTRY(state, payload) {
		state.country = payload;
	},
	UPDATE_CUSTOMER_NAME(state, payload) {
		state.customerName = payload;
	},
	SORT_PRICE(state, data) {
		if (state.copyArticles.data) {
			const filterPrice = state.copyArticles.data.filter((item) => {
				return (
					parseFloat(item.price) >= parseFloat(data[0]) &&
					parseFloat(item.price) <= parseFloat(data[1])
				);
			});
			state.articles.data = filterPrice;
		}
	},
	UPDATE_PROVIDERS(state, payload) {
		state.webshopProviders = payload;
	},
	UPDATE_WEBSHOP_PRODUCT(state, payload) {
		state.webshopProduct = JSON.parse(JSON.stringify(payload));
		state.copyWebshopProduct = JSON.parse(JSON.stringify(payload));
	},
	RESET_WEBSHOP_PRODUCT(state, _) {
		state.webshopProduct = state.copyWebshopProduct;
	},
	UPDATE_USER_SUBCRIPTION_TYPE(state, payload) {
		state.subscriptionType = payload;
	},
	UPDATE_USER_SUBCRIPTION(state, payload) {

		state.userSubscription = {};
		state.userSubscription = JSON.parse(JSON.stringify(payload));

	},
	UPDATE_CATEGORY(state, payload) {
		state.category = payload;
	},
	UPDATE_INTEGRATION_METRIC(state, payload) {
		state.integrationMetrics = payload;
	},
	UPDATED_CONNECTED_WEBSHOP(state, payload) {
		state.connectedWebshop = payload;
	},
	UPDATE_CONNECTED_SHOP_LIST(state, payload) {
		state.connectedShopList = payload;
	},
	UPDATE_NON_CONNECTED_SHOP_LIST(state, payload) {
		state.nonConnectedShopList = payload;
	},
	UPDATE_SHOP_USERS(state, payload) {
		state.shopUsers = JSON.parse(JSON.stringify(payload));
		state.copyShopUsers = JSON.parse(JSON.stringify(payload));
	},
	UPDATE_APPLICATION_LOG(state, payload) {
		state.applicationLog = JSON.parse(JSON.stringify(payload));
		state.copyApplicationLog = JSON.parse(JSON.stringify(payload));
	},
	STORE_BANK_ID(state, payload) {
		state.bankID = payload;
	},
	FILTER_SEARCH(state, payload) {
		const filterName = state.copyArticles.data.filter((item) =>
			item.description
				.toLowerCase()
				.includes(`${payload.value.toLowerCase()}`)
		);
		state.articles.data = filterName;
	},
	FILTER_ALL_INTEGRATION_PRODUCT(state, _) {
		state.webshopProduct.data = state.copyWebshopProduct.data;
	},
	FILTER_SEARCH_INTEGRATION_PRODUCT(state, payload) {
		const filterName = state.copyWebshopProduct.data.filter((item) => {
			item.name.toLowerCase().includes(`${payload.value.toLowerCase()}`);
		});

		const filterStatus = state.copyWebshopProduct.data.filter((item) => {
			item.status
				.toLowerCase()
				.includes(`${payload.value.toLowerCase()}`);
		});

		if (filterName.length > 0) {
			state.webshopProduct.data = filterName;
		} else if (filterStatus.length > 0) {
			state.webshopProduct.data = filterStatus;
		} else {
			state.webshopProduct.data = [];
		}
	},
	FILTER_ALL(state, _) {
		state.articles.data = state.copyArticles.data;
	},
	EMPTY_CART(state, _) {
		console.log("before", state.cartProduct);
		state.cartProduct = [];
		console.log("after", state.cartProduct);
	},
	ADD_CART_PRODUCT(state, data) {
		/*
		 *Method handles adding product to cart and also apply discount if specified
		 *
		 */

		// const newProductIndex = state.articles.data.findIndex(el => el.id === data.product.id)
		// const newStateProductArray = [...state.articles.data]
		let price = data.product.sales_price;
		let apply_discount = false;
		if (data.hasOwnProperty("apply_discount")) {
			if (data.apply_discount === true) {
				price = data.product.discounted_price;
				apply_discount = true;
			}
		}
		// newStateProductArray[newProductIndex].apply_discount = apply_discount
		// newStateProductArray[newProductIndex] = {
		// 	...newStateProductArray[newProductIndex],
		// 	added: true,
		// 	qty: data.qty,
		// 	priceIncVat: CalculatePriceIncVat(
		// 		newStateProductArray[newProductIndex].vat_rate,
		// 		price,
		// 		data.qty
		// 	)
		// }
		let newProductInfo = {
			...data.product,
			added: true,
			apply_discount: apply_discount,
			qty: data.qty,
			priceIncVat: CalculatePriceIncVat(
				data.product.vat_rate,
				price,
				data.qty
			),
		};
		// Check if product_id is in cart
		const check = state.cartProduct.findIndex(
			(el) => parseInt(el.id) === parseInt(data.product.id)
		);

		const findProduct = state.cartProduct.find(
			(el) => parseInt(el.id) === parseInt(data.product.id)
		);

		console.log(state.cartProduct);
		console.log(findProduct);
		console.log(data.product.id);
		const existingQty = findProduct ? findProduct?.qty : 0;
		console.log(
			"🚀 ~ file: index.js:177 ~ ADD_CART_PRODUCT ~ existingQty:",
			existingQty
		);
		const newQty = newProductInfo?.qty;
		console.log(
			"🚀 ~ file: index.js:179 ~ ADD_CART_PRODUCT ~ newQty:",
			newQty
		);
		const aggregatedQty = existingQty + newQty;

		if (check === -1) {
			// Add to basket
			state.cartProduct.push(newProductInfo);
		} else {
			const cartIndex = state.cartProduct.findIndex(
				(el) => el.id === data.product.id
			);
			const newCartArray = [...state.cartProduct];
			if (data.qty !== 0) {
				newCartArray[cartIndex] = {
					...newCartArray[cartIndex],
					qty: aggregatedQty,

					// qty: parseFloat(data.qty),
					priceIncVat: CalculatePriceIncVat(
						newCartArray[cartIndex].vat_rate,
						price,
						data.qty
					),
				};
				state.cartProduct = newCartArray;
			} else {
				state.cartProduct.splice(cartIndex, 1);
			}
		}

		// Store in localstorage
		saveCartItem(state.cartProduct);
	},

	UPDATE_CART_QUANTITY(state, data) {
		/**
		 * This method  handles updating product quantity inside cart
		 * parameter: state ,data
		 *
		 * its also add or remove extra item from the product cart if there is an increase or decrease in product quantity
		 */

		const cartIndex = state.cartProduct.findIndex(
			(item) => item.id === data.product.id
		);
		if (cartIndex > -1) {
			const newCartArray = [...state.cartProduct];
			if (data.qty !== 0) {
				let product = newCartArray[cartIndex];
				if (product.hasOwnProperty("extra_item")) {
					let extra_item = product.extra_item;
					if (parseInt(data.qty) > product.extra_item.length) {
						// if new quantity  is greater than the length of the extra item, add new additional items to the fields
						let addQty =
							parseInt(data.qty) - product.extra_item.length;
						for (let j = 1; j <= parseInt(addQty); j++) {
							extra_item.push({ item: "" });
						}
					} else if (product.extra_item.length > parseInt(data.qty)) {
						// if the extra item is greater than the new quantity remove equivalent items from it
						let subQty =
							product.extra_item.length - parseInt(data.qty);
						for (let i = 1; i <= parseInt(subQty); i++) {
							extra_item.pop();
						}
					}
					newCartArray[cartIndex].extra_item = extra_item;
				}
				newCartArray[cartIndex] = {
					...newCartArray[cartIndex],
					qty: parseFloat(data.qty),
				};
				state.cartProduct = newCartArray;
			} else {
				state.cartProduct.splice(cartIndex, 1);
			}
			saveCartItem(state.cartProduct);
		}
	},
	REMOVE_CART_PRODUCT(state, data) {
		if (data.remove) {
			// Remove single product
			const cartIndex = state.cartProduct.findIndex(
				(el) => el.id === data.product.id
			);
			const newCartArray = [...state.cartProduct];
			newCartArray[cartIndex] = {
				...newCartArray[cartIndex],
				qty: data.qty,
				priceIncVat: CalculatePriceIncVat(
					newCartArray[cartIndex].vat_rate,
					newCartArray[cartIndex].sales_price,
					data.qty
				),
			};
			// Check if item has qty 0 and remove
			if (newCartArray[cartIndex] !== undefined) {
				if (newCartArray[cartIndex].qty === 0) {
					// Remove entire product
					state.cartProduct.splice(cartIndex, 1);
				} else {
					state.cartProduct = newCartArray;
				}
			}
		} else {
			const basketArray = state.cartProduct.filter(
				(item) => item.id !== data.product.id
			);
			state.cartProduct = basketArray;
		}

		// Update localstoraget
		saveCartItem(state.cartProduct);
		// window.localStorage.setItem('cart_item', JSON.stringify(state.cartProduct))
	},
	SORT_SHOP_USERS_FIRSTNAME_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.first_name && b.first_name) {
				return a.first_name > b.first_name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_SHOP_USERS_FIRSTNAME_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.first_name && a.first_name) {
				return b.first_name > a.first_name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_SHOP_USERS_LASTNAME_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.last_name && b.last_name) {
				return a.last_name > b.last_name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_SHOP_USERS_LASTNAME_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.last_name && a.last_name) {
				return b.last_name > a.last_name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_SHOP_USERS_ROLL_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.user && b.user) {
				return a.user.groups[0].name > b.user.groups[0].name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_SHOP_USERS_ROLL_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.user && a.user) {
				return b.user.groups[0].name > a.user.groups[0].name;
			}
		});
		state.shopUsers = sortAsc;
	},
	SORT_LOG_INITIATOR_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a["initiator__username"] === b["initiator__username"]
				? 0
				: a["initiator__username"] > b["initiator__username"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortAsc;
	},
	SORT_LOG_INITIATOR_DESC(state, _) {
		// const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
		// 	return b.initiator__username < a.initiator__username
		// })
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a["company__name"] === b["initiator__username"]
				? 0
				: a["initiator__username"] < b["initiator__username"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortDesc;
	},
	SORT_LOG_DESCRIPTION_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a["description"] === b["description"]
				? 0
				: a["description"] > b["description"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortAsc;
	},
	SORT_LOG_DESCRIPTION_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a["description"] === b["description"]
				? 0
				: a["description"] < b["description"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortDesc;
	},
	SORT_LOG_DATE_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return new Date(a.date) - new Date(b.date);
		});
		state.applicationLog.data = sortAsc;
	},
	SORT_LOG_DATE_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return new Date(b.date) - new Date(a.date);
		});
		state.applicationLog.data = sortDesc;
	},
	SORT_LOG_TYPE_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a["event_state"] === b["event_state"]
				? 0
				: a["event_state"] > b["event_state"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortAsc;
	},
	SORT_LOG_TYPE_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a["event_state"] === b["event_state"]
				? 0
				: a["event_state"] < b["event_state"]
				? 1
				: -1;
		});
		state.applicationLog.data = sortDesc;
	},

	SORT_PRODUCT_SKU_ASC(state, _) {
		const sortAsc = state.copyWebshopProduct.data.sort((a, b) => {
			return a.sku - b.sku;
		});
		state.webshopProduct.data = sortAsc;
	},
	SORT_PRODUCT_SKU_DESC(state, _) {
		const sortDesc = state.copyWebshopProduct.data.sort((a, b) => {
			return b.sku - a.sku;
		});
		state.webshopProduct.data = sortDesc;
	},
	SORT_PRODUCT_QTY_ASC(state, _) {
		const sortAsc = state.copyWebshopProduct.data.sort((a, b) => {
			return a.stock_quantity - b.stock_quantity;
		});
		state.webshopProduct.data = sortAsc;
	},
	SORT_PRODUCT_QTY_DESC(state, _) {
		const sortDesc = state.copyWebshopProduct.data.sort((a, b) => {
			return b.stock_quantity - a.stock_quantity;
		});
		state.webshopProduct.data = sortDesc;
	},
	SORT_PRODUCT_NAME_ASC(state, _) {
		const sortAsc = state.copyWebshopProduct.data.sort((a, b) => {
			return a.name > b.name;
		});
		state.webshopProduct.data = sortAsc;
	},
	SORT_PRODUCT_NAME_DESC(state, _) {
		const sortDesc = state.copyWebshopProduct.data.sort((a, b) => {
			return b.name > a.name;
		});
		state.webshopProduct.data = sortDesc;
	},
	SORT_PRODUCT_SUPPLY_QTY_ASC(state, _) {
		const sortAsc = state.copyWebshopProduct.data.sort((a, b) => {
			return a.supplier_quantity - b.supplier_quantity;
		});
		state.webshopProduct.data = sortAsc;
	},
	SORT_PRODUCT_SUPPLY_QTY_DESC(state, _) {
		const sortDesc = state.copyWebshopProduct.data.sort((a, b) => {
			return b.supplier_quantity - a.supplier_quantity;
		});
		state.webshopProduct.data = sortDesc;
	},
	UPDATE_BUYER_TOUR_ALL(state, payload) {
		state.buyerTour = payload;
		storeBuyerTourGuide(state.buyerTour);
	},
	UPDATE_BLOG_CATEGORY(state, payload) {
		state.blogCategory = payload;
	},
	UPDATE_BLOG_POST(state, payload) {
		state.blogPost = payload;
	},
	UPDATE_SINGLE_BLOG_POST(state, payload) {
		state.singleBlogPost = payload;
	},
	UPDATE_CANCEL_CHECKOUT(state, payload) {
		state.cancelCheckout = payload;
	},
	UPDATE_ARCHIVE(state, payload) {
		state.archives = payload.data;
	},
	UPDATE_FAQZ(state, payload) {
		state.faqPost = payload;
	},
	UPDATE_FAQ_CATEGORY(state, payload) {
		state.faqPostCategory = payload;
	},
	UPDATE_CHECKOUT_CONFIG(state, payload) {
		state.companyCheckoutConfig = payload.data;
	},
	UPDATE_SINGLE_ARTICLE(state, payload) {
		state.singleArticle = payload;
	},
	UPDATE_ARTICLE_SPECIFICATION(state, payload) {
		state.articleSpecification = payload;
	},
	UPDATE_ARTICLE_PARAMS(state, payload) {
		state.articleParams = payload;
	},
	UPDATE_CART_WITH_EXTRA_ITEM(state, payload) {
		let articleIndex = state.cartProduct.findIndex(
			(item) => item.id === parseInt(payload.id)
		);
		if (articleIndex > -1) {
			state.cartProduct[articleIndex]["extra_item"] = payload.extra_item;
			// Store in localstorage
			saveCartItem(state.cartProduct);
		}
	},
	REMOVE_EXTRA_ITEM_FROM_CART(state, payload) {
		let articleIndex = state.cartProduct.findIndex(
			(item) => item.id === parseInt(payload.id)
		);
		if (articleIndex > -1) {
			state.cartProduct[articleIndex]["extra_item"] = {};
			// Store in localstorage
			saveCartItem(state.cartProduct);
		}
	},

	UPDATE_DELIVERY_ADDRESS_DATA(state, payload) {
		state.deliveryAddress = payload.data;
	},
	UPDATE_END_CUSTOMER(state, payload) {
		state.END_CUSTOMER = payload.data;
	},
	UPDATE_CURRENT_COMPANY(state, payload) {
		state.currentCompany = payload.data;
		saveCompanyInfo(payload.data);
	},
	UPDATE_USER_NOTIFICATION(state, payload) {
		state.userNotification = payload;
	},
	UPDATE_ALL_NOTIFICATION(state, payload) {
		state.allNotification = payload;
	},

	USER_INFO(state, payload) {
		state.userInfo = payload;
	}
};

// function removeCartObject () {
//     window.localStorage.setItem('cart_item', JSON.stringify([]))
// }

// function storeCartObject (data) {
//     window.localStorage.setItem('cart_item', JSON.stringify(data))
// }
